<template>
  <div class="contact" :class="{ 'contact--absolute' :  isUserLoggedIn == false}">
    <div class="contact-background">
      <!-- <img src="../assets/img/background.jpg" alt=""> -->
      <div class="contact-info">
        <h1>CONTACT US</h1>
        <p>Please feel free to email us at <a href="mailto:ukcontacts@wineinstitute.org">ukcontacts@wineinstitute.org</a> or enter your details to learn more about California Wines and stay up to date with events.</p>
      </div>
    </div>
  </div>
</template>
<script>
  import store from '@/store/index.js'

  export default {
  name: 'Footer',

  computed: {
    isUserLoggedIn() {
      return store.state.isLoggedIn
    }
  },
}
</script>